package org.luxons.sevenwonders.ui.names

import kotlin.random.Random

internal fun randomGameName(): String = gameNames.random()

internal fun randomGreekName(): String {
    val randName = prefixes.random() + suffixes.random()
    return if (Random.nextBoolean()) randName else "$randName of ${cities.random()}"
}

private val gameNames = listOf(
    "Age of Antiquity",
    "Age of Civilization",
    "Age of Discovery",
    "Age of Empires",
    "Age of Wonders",
    "Ancient Capitals",
    "Ancient Kingdoms",
    "Ancient Wonders",
    "Cities of Antiquity",
    "City of Wonders",
    "Empire Builders",
    "Empires of the Past",
    "Great Monuments",
    "Legendary Cities",
    "Legends of the Past",
    "Lost Empires",
    "Magnificent Monuments",
    "Magnificent Seven",
    "Monuments of the Past",
    "Monuments of the World",
    "Mythical Kingdoms",
    "Secrets of the Past",
    "Seven Ancient Wonders",
    "Seven Colossi",
    "Seven Kingdoms",
    "Seven Marvels",
    "Seven Wonders Adventures",
    "Seven Wonders Chronicles",
    "Seven Wonders Enigma",
    "Seven Wonders Expedition",
    "Seven Wonders Frontier",
    "Seven Wonders Legacy",
    "Seven Wonders Odyssey",
    "Seven Wonders Quest",
    "Seven Wonders Saga",
    "Seven Wonders Treasures",
    "Seven Wonders Voyage",
    "Seven Wonders and Beyond",
    "The Great Discoveries",
    "The Legacy of Wonders",
    "The Magic of Seven",
    "The Marvelous Seven",
    "The Mysteries of Antiquity",
    "The Seven Continents",
    "The Seven Kingdoms",
    "The Seven Legends",
    "The Seven Secrets",
    "The Seven Treasures",
    "Wonders of Nature",
    "Wonders of the Ages",
    "Wonders of the World",
    "Wonders of Time",
    "World Treasures",
)

private val prefixes =
    listOf(
        "Aba",
        "Abde",
        "Abre",
        "Aby",
        "Aca",
        "Acle",
        "Acri",
        "Acro",
        "Adme",
        "Adra",
        "Aea",
        "Aegi",
        "Aei",
        "Aeo",
        "Aese",
        "Aeto",
        "Aga",
        "Age",
        "Agi",
        "Agri",
        "Aia",
        "Aka",
        "Akti",
        "Ala",
        "Alco",
        "Ale",
        "Alka",
        "Alki",
        "Alo",
        "Alphi",
        "Ama",
        "Ame",
        "Ami",
        "Amphi",
        "Ana",
        "Anchi",
        "Andro",
        "Ane",
        "Anta",
        "Anthe",
        "Anti",
        "Ape",
        "Aphi",
        "Apo",
        "Arca",
        "Arche",
        "Arci",
        "Arga",
        "Ari",
        "Arra",
        "Arte",
        "Asca",
        "Asta",
        "Asty",
        "Atro",
        "Atta",
        "Aute",
        "Bace",
        "Bae",
        "Bali",
        "Bio",
        "Boe",
        "Bria",
        "Care",
        "Carpo",
        "Casto",
        "Cea",
        "Cebri",
        "Cele",
        "Cephi",
        "Chae",
        "Chare",
        "Chari",
        "Choe",
        "Chromi",
        "Chryso",
        "Cine",
        "Cisse",
        "Clea",
        "Cleo",
        "Clyto",
        "Cnoe",
        "Coe",
        "Cordy",
        "Cory",
        "Crati",
        "Creti",
        "Croe",
        "Ctea",
        "Cyre",
        "Dae",
        "Dami",
        "Damo",
        "Dana",
        "Daphi",
        "Davo",
        "Dei",
        "Dema",
        "Demo",
        "Deo",
        "Derky",
        "Dexi",
        "Dia",
        "Dio",
        "Dithy",
        "Dore",
        "Dori",
        "Doro",
        "Drya",
        "Dymno",
        "Eche",
        "Eio",
        "Ela",
        "Elpe",
        "Empe",
        "Endy",
        "Enge",
        "Epa",
        "Epe",
        "Ephi",
        "Era",
        "Ere",
        "Ergi",
        "Erxa",
        "Euca",
        "Euche",
        "Eudo",
        "Eue",
        "Euge",
        "Euma",
        "Eune",
        "Eury",
        "Euthy",
        "Eva",
        "Eve",
        "Fae",
        "Gale",
        "Gany",
        "Gaua",
        "Genna",
        "Gera",
        "Glau",
        "Gorgo",
        "Gyra",
        "Hae",
        "Hagi",
        "Hali",
        "Harma",
        "Harmo",
        "Harpa",
        "Hege",
        "Heira",
        "Heiro",
        "Helge",
        "Heli",
        "Hera",
        "Hermo",
        "Hiero",
        "Hippo",
        "Hya",
        "Hype",
        "Hyrca",
        "Iatro",
        "Iby",
        "Ica",
        "Ido",
        "Illy",
        "Ina",
        "Iphi",
        "Iro",
        "Isa",
        "Isma",
        "Iso",
        "Ithe",
        "Kae",
        "Kale",
        "Kalli",
        "Kame",
        "Kapa",
        "Kari",
        "Karo",
        "Kau",
        "Keo",
        "Kera",
        "Kleo",
        "Krini",
        "Krito",
        "Labo",
        "Lae",
        "Lama",
        "Lamu",
        "Lao",
        "Laso",
        "Lea",
        "Lei",
        "Leo",
        "Linu",
        "Luko",
        "Lyca",
        "Lyco",
        "Lysa",
        "Lysi",
        "Maca",
        "Macha",
        "Mae",
        "Maia",
        "Maka",
        "Male",
        "Mante",
        "Marci",
        "Marsy",
        "Mega",
        "Megi",
        "Mela",
        "Mele",
        "Metho",
        "Midy",
        "Mise",
        "Mono",
        "Morsi",
        "Myrsi",
        "Naste",
        "Nausi",
        "Nea",
        "Nele",
        "Neri",
        "Nica",
        "Nico",
        "Nire",
        "Nomi",
        "Nycti",
        "Oche",
        "Ocho",
        "Oea",
        "Oene",
        "Oeno",
        "Oile",
        "Ona",
        "One",
        "Ophe",
        "Ori",
        "Orsi",
        "Ory",
        "Pae",
        "Pala",
        "Pana",
        "Pandi",
        "Pani",
        "Panta",
        "Para",
        "Pata",
        "Peiri",
        "Pele",
        "Peli",
        "Peri",
        "Phae",
        "Phala",
        "Philo",
        "Phyla",
        "Poe",
        "Poly",
        "Praxi",
        "Prota",
        "Pryta",
        "Saby",
        "Saty",
        "Scama",
        "Scytha",
        "Sele",
        "Sila",
        "Simo",
        "Sisy",
        "Sopho",
        "Stesa",
        "Sya",
        "Sylo",
        "Syne",
        "Tala",
        "Teba",
        "Tele",
        "Tene",
        "Theo",
        "Therse",
        "Thrasy",
        "Tima",
        "Tiry",
        "Trio",
        "Xanthi",
        "Xena",
        "Xeno",
    )

private val suffixesMale =
    listOf(
        "ndros",
        "bios",
        "bulos",
        "chus",
        "cles",
        "cydes",
        "damos",
        "dides",
        "don",
        "doros",
        "dotus",
        "gnis",
        "goras",
        "kles",
        "kos",
        "krates",
        "laktos",
        "laus",
        "leon",
        "llias",
        "llos",
        "llus",
        "machos",
        "machus",
        "menes",
        "menos",
        "mos",
        "ndius",
        "nes",
        "neus",
        "nidas",
        "nides",
        "nos",
        "nthius",
        "patros",
        "phanes",
        "phantes",
        "phimus",
        "phnus",
        "phon",
        "phoros",
        "phorus",
        "phus",
        "pides",
        "pompos",
        "pompus",
        "pon",
        "ppos",
        "rax",
        "reas",
        "rides",
        "ros",
        "sias",
        "sides",
        "sius",
        "stius",
        "stor",
        "stos",
        "stus",
        "talos",
        "thenes",
        "theus",
        "tios",
    )

private val suffixesFemale =
    listOf(
        "ndria",
        "boea",
        "casta",
        "caste",
        "cheia",
        "chis",
        "cleia",
        "dee",
        "deia",
        "dike",
        "dina",
        "doce",
        "dora",
        "dusa",
        "gaea",
        "kia",
        "laia",
        "lea",
        "line",
        "llis",
        "lope",
        "mache",
        "mathe",
        "meda",
        "mede",
        "meia",
        "mela",
        "mene",
        "mere",
        "mia",
        "mina",
        "mpias",
        "ndra",
        "ne",
        "neira",
        "nessa",
        "nia",
        "nice",
        "niera",
        "nike",
        "nippe",
        "nna",
        "nome",
        "nope",
        "nta",
        "nthia",
        "pe",
        "phae",
        "phana",
        "phane",
        "phile",
        "phobe",
        "phone",
        "pia",
        "polis",
        "pris",
        "pyle",
        "reia",
        "rine",
        "ris",
        "rista",
        "rpia",
        "sia",
        "ssa",
        "steia",
        "stis",
        "syne",
        "ta",
        "tea",
        "thea",
        "theia",
        "thia",
        "thippe",
        "thra",
        "thusa",
        "thyia",
        "tis",
        "trite",
    )

private val suffixes = suffixesMale + suffixesFemale

private val cities =
    listOf(
        "Argos",
        "Assos",
        "Astypalaia",
        "Carystus",
        "Chalcis",
        "Chios",
        "Corfu",
        "Corinth",
        "Eretria",
        "Erythrae",
        "Karpathos",
        "Kasos",
        "Kos",
        "Leros",
        "Lindos",
        "Marathon",
        "Megara",
        "Miletus",
        "Mytilene",
        "Naxos",
        "Oenoe",
        "Paros",
        "Patmos",
        "Patras",
        "Phocis",
        "Rhodes",
        "Salamis",
        "Skiathos",
        "Sparta",
        "Thasos",
        "Thebes",
    )
